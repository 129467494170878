export const betIsLocked = (suspensionReason: any, isLive?: any) =>
	// Function that checks if a bet is open
	// The function receives a bet object as a parameter
	//  and returns a boolean value

	//value of bet suspension reasons
	// 1 -	All chosen Providers suspended their odds.
	// 3 - 	Above 50% of providers in the list have suspended their odds.
	// 4 -	A minimum number of providers is required to offer the market.
	// 5 - One of your mandatory providers has not provided or suspended its odds.
	// 6 -  The bet is irrelevant according to the current livescore data. Relevant for InPlay markets only. For example Score 2:0, Next Goal Line: 2.0 is still open.
	// 7 - Odds are out of min/max defined range.
	// 8 - Line types removal will lead to the related bet line suspension.
	// 9 - Out of lines range between the mainline and the predefined range.
	// 10 - Fixture status requires odds suspension. For example, PreMatch fixture received the status “In Progress”.
	// 11 - The bet is irrelevant according to the current mainline.
	// 12 - The bet is suspended according to the data limit configuration.
	// 13 - Odds are suspected as outlier according to other LSports providers.
	// 14 - Each setting change that causes settings removal will lead to bet suspension. For example: If you remove market settings for 1X2 - your 1X2 bets (1,2 and X) will be suspended.
	// 15 - TRADE360 cannot calculate the fair price of the market's providers.
	// 16 - The bet's margin is negative according to the existing configuration.
	// 17 - The user suspended the bets manually
	{
		const reasonsToLock = [6, 10, 12, 13, 15, 16, 17];

		if (suspensionReason) {
			if (reasonsToLock.includes(suspensionReason.value)) {
				return true;
			}
		}

		if (isLive) {
			return true;
		}

		return false;

		// return true;
	};
