import BigNumber from "bignumber.js";

const parseNameBet = (bet: any, participants: any) => {
	if (bet === "vs") {
		return;
	}

	if (bet) {
		switch (bet?.name) {
			case "1":
				bet.filteredName = participants[0]?.name_ko;
				break;
			case "1 And Under":
				bet.filteredName = `${participants[0]?.name_ko} + 언더`;
				break;
			case "1 And Over":
				bet.filteredName = `${participants[0]?.name_ko} + 오버`;
				break;
			case "2":
				bet.filteredName = participants[1]?.name_ko;
				break;
			case "2 And Under":
				bet.filteredName = `${participants[1]?.name_ko} + 언더`;
				break;
			case "2 And Over":
				bet.filteredName = `${participants[1]?.name_ko} + 오버`;
				break;
			case "X":
				bet.filteredName = "Empate";
				break;
			case "12":
				bet.filteredName = "Vitória do time da casa ou vitória do time de fora";
				break;
			case "1X":
				bet.filteredName = "Vitória ou empate do time da casa";
				break;
			case "X2":
				bet.filteredName = "Vitória ou empate do time de fora";
				break;
			default:
				bet.filteredName = bet?.name;
				break;
		}
	}
};

export const SecondaryMarketBetsParser = (market: any, participants: any) => {
	// if (primary && !live)
	// {
	//     return SportPrimaryMarketsBuilder(sport)
	// }

	// if (primary && live)
	// {
	//     return LivePrimaryMarketsBuilder()
	// }

	// let bets: any;
	// markets.map((fixtureMarket: any) => {
	//     if (fixtureMarket?.market?.id == market?.id) {
	//         fixtureMarket.bets.map((bet: any) => parseNameBet(bet, participants))
	//         bets = fixtureMarket.bets;
	//     }
	//})

	if (!market || market?.length === 0) {
		return [];
	}

	market.bets.map((bet: any) => parseNameBet(bet, participants));

	if (market.bets?.[0]?.baseline?.match(/\d+\.\d+\s+\(/))
		return createBetHandicapGrid(market.bets);

	if (market.bets?.[0]?.name?.match(/^\d+\-\d+$/))
		// 1-0, 2-0, 3-3...
		//score grid type
		return createBetScoreGrid(market.bets);

	if (market.bets?.[0]?.line?.match(/\d+\.\d+\s+\(/)) {
		market.bets.map((bet: any) => {
			bet.baseline = bet.line;
		});
		return createBetHandicapGrid(market.bets);
	}

	//if (fixture[0]?.name?.match(/^[12] And (Under|Over)$/))
	// 1-0, 2-0, 3-3...
	//return 'UO'

	return createDefaultGrid(market.bets);
};

export const getPrimaryMarketReducedName = (
	market: any,
	selectedLanguage: any,
) => {
	let name: anu;

	if (market.name.includes("Under/Over")) {
		name = selectedLanguage === "pt" ? "Under/Over" : "오버언더";
	} else if (market.name.includes("Handicap")) {
		name = name = selectedLanguage === "pt" ? "Handicap" : "핸디캡";
	} else {
		name =
			selectedLanguage === "pt"
				? market?.name_br.replaceAll("_", " ")
				: market?.name_ko.replaceAll("_", " ");
	}

	return name;
};

const createBetScoreGrid = (bets: any) => {
	const grid: any = [[], [], []];
	// const openBets  = bets.filter((obj: any) => obj.status.value === 1)

	// openBets.map((bet: any) =>
	bets.map((bet: any) => {
		if (!bet.name.match(/^\d+\-\d+$/i)) {
			grid[0].push(bet);
		} else {
			const [num1str, num2str] = bet.name.split("-");
			const num1 = Number(num1str);
			const num2 = Number(num2str);

			if (num1 > num2) grid[0].push(bet);
			else if (num1 === num2) grid[1].push(bet);
			else grid[2].push(bet);
		}
	});

	const lines = Math.max(grid[0].length, grid[1].length, grid[2].length);
	grid[0] = grid[0].sort((a: any, b: any) =>
		a.name.split("-")[0]?.padStart(3, "0") <
		b.name.split("-")[0]?.padStart(3, "0")
			? -1
			: 0,
	);

	// Draw sort
	grid[1] = grid[1].sort((a: any, b: any) =>
		a.name.split("-")[0]?.padStart(3, "0") <
		b.name.split("-")[0]?.padStart(3, "0")
			? -1
			: 0,
	);

	// Away team sort
	grid[2] = grid[2].sort((a: any, b: any) =>
		a.name.split("-")[1]?.padStart(3, "0") <
		b.name.split("-")[1]?.padStart(3, "0")
			? -1
			: 0,
	);

	const grid2: any = [];

	for (let line = 0; line < lines; line++) {
		if (!grid2[line]) {
			grid2[line] = { bets: [], key: line };
		}

		grid.map((col: any) => {
			col[line]
				? // ? grid2[line].bets.push(col[line].data())
					grid2[line].bets.push(col[line])
				: grid2[line].bets.push({ empty: true });
		});
	}

	// remove o any other score
	for (let i = 0; i < grid2?.length; i++) {
		const bets = grid2[i]?.bets;

		for (let j = 0; j < bets?.length; j++) {
			if (bets[j]?.name === "Any Other Score") {
				bets[j] = { empty: true };
			}
		}
	}

	// verifica se possui linha vazia e remove
	const newGrid = [];

	for (let i = 0; i < grid2?.length; i++) {
		let counter = 0;
		const bets = grid2[i]?.bets;

		for (let j = 0; j < bets?.length; j++) {
			if (bets[j]?.empty) {
				counter += 1;
			}
		}

		if (counter !== 3) {
			newGrid.push(grid2[i]);
		}
	}

	return newGrid;
};

const createDefaultGrid = (bets: any) => {
	let grid: any = {};
	// const openBets = bets?.filter((obj: any) => obj?.status?.value === 1)

	// openBets?.map((bet: any) =>
	bets?.map((bet: any) => {
		let lineKey: string = bet?.line;

		if (lineKey?.match(/\d+\.\d+\s+\(/))
			// -3.5 (0.0), -4.5 (0.0)...
			lineKey = Number(lineKey.split(" ")[0]).toString();

		if (!grid[lineKey]) grid[lineKey] = { bets: [], key: lineKey };

		// grid[lineKey].bets.push(bet.data())

		grid[lineKey].bets.push(bet);
	});

	for (const lineKey in grid) // sort bets
		grid[lineKey].bets = grid[lineKey].bets.sort((a: any, b: any) => {
			if (
				(a.name === "Over" && b.name === "Under") ||
				(a.name === "Odd" && b.name === "Even") ||
				(a.name === "Yes" && b.name === "No") ||
				(a.name === "1" && b.name === "2") ||
				(a.name === "1" && b.name === "X") ||
				(a.name === "X" && b.name === "2")
			)
				return -1;

			return 0;
		});

	const newGrid: any = {};
	Object.keys(grid).forEach((key) => {
		const bets = grid[key].bets;

		if (bets.length <= 3) {
			newGrid[key] = { bets, key };
		} else {
			let counter = 0;
			const lineKey = key;

			while (bets.length > 0) {
				const slicedBets = bets.splice(0, 2);
				const newKey = `${lineKey}-${counter}`;
				newGrid[newKey] = { bets: slicedBets, key: newKey };
				counter++;
			}
		}
	});

	grid = newGrid;

	return Object.values(grid);
};

const createBetHandicapGrid = (bets: any) => {
	let filteredObjects: any;
	let filteredObjects2: any;
	const grid = [];

	// filtra apenas bets em estado open
	// const openBets = bets.filter((obj: any) => obj.status.value === 1)

	filteredObjects = bets.filter((obj: any) => obj.name === "1");
	filteredObjects2 = bets.filter((obj: any) => obj.name === "2");

	filteredObjects.sort((a: any, b: any) => {
		const lineA = Number.parseFloat(a.baseline?.split(" ")[0]);
		const lineB = Number.parseFloat(b.baseline?.split(" ")[0]);

		return lineA - lineB;
	});

	filteredObjects2.sort((a: any, b: any) => {
		const lineA = Number.parseFloat(a.baseline?.split(" ")[0]);
		const lineB = Number.parseFloat(b.baseline?.split(" ")[0]);

		return lineA - lineB;
	});

	for (let i = 0; i < filteredObjects.length; i++) {
		if (!filteredObjects[i] || !filteredObjects[i]) {
			continue;
		}

		const _lineA = filteredObjects[i]?.line?.split(" ")[0];
		const _lineB = filteredObjects2[i]?.line?.split(" ")[0];

		//fallback caso nao tenha baseline para jogos antigos
		// if (!lineA && filteredObjects[i]?.baseline)
		// {
		//     filteredObjects[i].baseline = filteredObjects[i]?.line
		// }

		// if (!lineB && filteredObjects2[i]?.baseline)
		// {
		//     filteredObjects2[i].baseline = filteredObjects2[i]?.line
		// }

		// if (filteredObjects[i].line?.split(" ")[0] !== filteredObjects2[i].line?.split(" ")[0])
		// {
		//     continue
		// }
		//grid.push(key: )
		// ind.push({ market: bets.market, bet: bets.find((element) => element.line === filteredObjects[Math.floor(filteredObjects.length / 2)]?.line) })
		// ind.push({ market: bets.market, bet: bets..find((element) => element.line === filteredObjects2[Math.floor(filteredObjects2.length / 2)]?.line) })

		if (
			filteredObjects[i].status.value === 1 &&
			filteredObjects2[i].status.value === 1
		) {
			grid.push({
				key: filteredObjects[i].baseline?.split(" ")[0],
				bets: [filteredObjects[i], filteredObjects2[i]],
				shouldFilter: true,
			});
		}
	}

	return grid;
};

export const SportPrimaryMarketsParser = (markets: any) => {
	// const getMarket = (market: any) => {
	//     const marketData = fixture?.markets?.find(
	//         (element: any) => element.market.id == market.id
	//     )

	//     if (marketData) {
	//         return marketData
	//     }
	// }

	if (!markets) {
		return;
	}

	//         return marketData
	//     }
	const betSort = (a: any, b: any) => {
		const order: any = { "1": 0, X: 1, vs: 1, baseline: 1, "2": 2 };
		return order[a.name] - order[b.name];
	};

	// const bets: any[] = []
	// sport?.primaryMarkets?.map((e: any, index: any) => {
	//     bets.push(getMarket(e))
	// })

	const grid: any = [];

	markets
		.filter((bet: any) => bet !== undefined)
		.map((e: any) => {
			let filteredBets: any = [];
			const bets = e?.bets;

			for (let i = 0; i < bets?.length; i++) {
				if (bets[i]?.status?.value === 1) {
					bets[i].checkLine = bets[i]?.line?.split(" ")[0];
					bets[i].checkBaseline = bets[i]?.baseline?.split(" ")[0];
					filteredBets?.push(bets[i]);
				}
			}

			// gambiarra, se o length vier 0 consideramos que todas foram suspensas
			if (filteredBets?.length === 0) {
				filteredBets = e?.bets;
			}

			if (filteredBets) {
				filteredBets?.sort((a: any, b: any) => {
					// Compare by name
					if (a?.name && b?.name && a?.name !== b?.name) {
						return a?.name?.localeCompare(b.name);
					}

					// If names are equal, compare by baseLine
					return a?.baseLine?.localeCompare(b?.baseLine);
				});
			}

			let lowestDiff: any;
			let targetId1: any;
			let targetId2: any;

			// NÃO MEXER NESSE CODIGO EM HIPOTESE ALGUMA!!!!!!!!!!!!!!!!!!!
			const half_length = Math.ceil(filteredBets?.length / 2);
			const leftSide = filteredBets?.slice(0, half_length);
			const rightSide = filteredBets?.slice(half_length, filteredBets?.length);

			// apenas p/ debug
			const diff: any = [];

			for (let i = 0; i < leftSide?.length; i++) {
				for (let j = 0; j < rightSide?.length; j++) {
					if (leftSide[i]?.checkBaseline === rightSide[j]?.checkBaseline) {
						const betDiff = Math.abs(leftSide[i]?.price - rightSide[j]?.price);

						diff.push(betDiff);

						if (lowestDiff === undefined) {
							lowestDiff = betDiff;
						}

						if (betDiff <= lowestDiff) {
							lowestDiff = betDiff;
							targetId1 = leftSide[i].id;
							targetId2 = rightSide[j].id;
						}
					}
				}
			}

			let containsOpenBet = true;

			for (let i = 0; i < filteredBets.length; i++) {
				if (filteredBets[i].status.value === 1) {
					break;
				}

				containsOpenBet = false;
			}

			if (e?.market.name.includes("1X2") || e?.market.name.includes("12")) {
				const filteredBets = e?.bets?.filter((bet: any) =>
					["1", "X", "2"].includes(bet.name),
				);

				if (filteredBets.length === 2) {
					filteredBets.splice(1, 0, { name: "vs" });
				}

				grid.push({
					market: e?.market,
					status: containsOpenBet,
					bets: filteredBets.sort(betSort),
				});
			} else if (e?.market.name.includes("Under")) {
				const bet1 = filteredBets?.find(
					(element: any) => element.id === targetId1,
				);
				const bet2 = filteredBets?.find(
					(element: any) => element.id === targetId2,
				);

				let status = false;

				if (bet1 && bet2) {
					if (bet1.status.value === 1 || bet2.status.value === 1) {
						status = true;
					}

					grid.push({
						market: e?.market,
						status: containsOpenBet,
						bets: [
							bet1,
							{
								name: "baseline",
								status: status,
								baseline: bet1.baseline ?? bet2.baseline,
							},
							bet2,
						],
					});
				}
			} else {
				const bet1 = filteredBets?.find(
					(element: any) => element.id === targetId1,
				);
				const bet2 = filteredBets?.find(
					(element: any) => element.id === targetId2,
				);

				let status = false;

				if (bet1 && bet2) {
					if (bet1.status.value === 1 || bet2.status.value === 1) {
						status = true;
					}

					grid.push({
						market: e?.market,
						status: containsOpenBet,
						bets: [
							bet1,
							{
								name: "baseline",
								status: status,
								baseline: bet1.baseline ?? bet2.baseline,
							},
							bet2,
						],
					});
				}
			}
		});

	return grid;
};

export const nameFilter = (market: any, selectedGame: any) => {
	if (selectedGame.sport.id === 6046) {
		//futebol
		if (!market?.name_ko.includes("1") && !market?.name_ko.includes("2")) {
			// market.name_ko = market?.name_ko.replace('전반전', '1전반전')
			// market.name_ko = market?.name_ko.replace('후반전', '2후반전')
		}
	} else if (selectedGame.sport.id === 154914) {
		//baseball
		market.name_ko = market?.name_ko.replace("전반전", "1이닝");
		market.name_ko = market?.name_ko.replace("후반전", "2이닝");
		market.name_ko = market?.name_ko.replace("1 피리어드", "1이닝");
		market.name_ko = market?.name_ko.replace("2 피리어드", "2이닝");
		market.name_ko = market?.name_ko.replace("3 피리어드", "3이닝");
		market.name_ko = market?.name_ko.replace("4 피리어드", "4이닝");
	} else if (selectedGame.sport.id === 154830) {
		//volley
		market.name_ko = market?.name_ko.replace("전반전", "1세트");
		market.name_ko = market?.name_ko.replace("후반전", "2세트");
		market.name_ko = market?.name_ko.replace("3 피리어드", "1세트");
		market.name_ko = market?.name_ko.replace("3 피리어드", "2세트");
		market.name_ko = market?.name_ko.replace("3 피리어드", "3세트");
	} else if (selectedGame.sport.id === 35232) {
		//ice hockey
		market.name_ko = market?.name_ko.replace("전반전", "1피어리드");
		market.name_ko = market?.name_ko.replace("후반전", "2피어리드");
		market.name_ko = market?.name_ko.replace("1 피리어드", "1피어리드 ");
		market.name_ko = market?.name_ko.replace("2 피리어드", "2피어리드 ");
		market.name_ko = market?.name_ko.replace("3 피리어드", "3피어리드 ");
		// market.name_ko.replace("", '3피어리드');
	} else if (selectedGame.sport.id === 48242) {
		//basquete
		market.name_ko = market?.name_ko.replace("전반전", "1쿼터");
		market.name_ko = market?.name_ko.replace("후반전", "2쿼터 ");
		market.name_ko = market?.name_ko.replace("1 피리어드", "1쿼터 ");
		market.name_ko = market?.name_ko.replace("2 피리어드", "2쿼터 ");
		market.name_ko = market?.name_ko.replace("3 피리어드", "3쿼터 ");
		market.name_ko = market?.name_ko.replace("4 피리어드", "4쿼터 ");
	}
};

export const betCheckLock = (_bet: any) => {
	return false;
};

export const calculatePrice = (price: any, config: any, dividends: any) => {
	if (config.sport !== null && typeof config.sport === "object") {
		const toCamelCase = (str: string) => {
			const words = str.split(" ");
			const camelCaseWords = words.map((word, index) => {
				if (index === 0) {
					return word.toLowerCase();
				}

				return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
			});
			return camelCaseWords.join("");
		};

		config.sport = toCamelCase(config.sport.name);
	}

	let priceFactor = 1;
	const key = config.live ? "live" : "sport";
	const sport = config.virtual ? "esport" : config.sport;
	priceFactor =
		dividends[key]?.[0] && typeof dividends[key][0][sport] === "number"
			? dividends[key][0][sport]
			: 1;

	BigNumber.config({ DECIMAL_PLACES: 20 });

	price = new BigNumber(price);
	const priceDiff = price.times(priceFactor).dividedBy(100);

	let newPrice = price.plus(priceDiff).dp(2, BigNumber.ROUND_DOWN).toNumber();

	if (newPrice < 1) {
		newPrice = 1;
	}

	return new Intl.NumberFormat("en", {
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
		// minimumSignificantDigits: 3,
		// maximumSignificantDigits: 3,
		roundingMode: "floor",
	}).format(newPrice);
};

export const LiveSportPrimaryMarketsParser = (fixture: any) => {
	const participants = fixture?.participants;
	const results = fixture?.livescore?.scoreboard?.results;

	if (fixture?.livescore?.scoreboard) {
		fixture.livescore.scoreboard.parsedPeriod = getPeriod(
			fixture?.livescore?.scoreboard?.period,
			fixture?.sport.id,
		);
	} else {
		fixture.livescore = { scoreboard: { parsedPeriod: "-" } };
	}

	const homeScore = results?.[0]?.value ?? 0;
	const awayScore = results?.[1]?.value ?? 0;

	fixture.parsedLiveScore = [
		{ participant: participants?.[0], score: homeScore },
		{ participant: participants?.[1], score: awayScore },
	];

	const sumaryMemo: any = [];

	if (fixture?.inPlay?.markets?.length > 0) {
		const markets = fixture?.inPlay?.markets?.inPlay?.markets;

		for (let i = 0; i < markets?.length; i++) {
			if (
				markets[i]?.market?.id === "1" ||
				markets[i]?.market?.id === "52" ||
				markets[i]?.market?.id === "226"
			) {
				sumaryMemo.push(markets[i]);
			}
		}

		const memo: any = sumaryMemo[0]?.bets;
		const aux = [];

		for (let i = 0; i < memo?.length; i++) {
			if (memo[i]?.name === "1") {
				aux[0] = memo[i];
			} else if (memo[i]?.name === "X") {
				aux[1] = memo[i];
			} else if (memo[i]?.name === "2") {
				aux[2] = memo[i];
			}
		}

		const bet = sumaryMemo[0];

		if (!aux[1]) {
			aux[1] = "vs";
		}

		if (bet) {
			sumaryMemo[0].bets = aux;
		}

		//setSumary(sumaryMemo)
	}

	return fixture;
};

export const getPeriod = (period: any, sportId: any) => {
	// console.warn('AQUI', period, sportId)
	if (!period?.value) {
		return "없는";
	}

	switch (period?.value) {
		case -1:
			return "없는";
		case 80:
			return "휴식 시간";
		case 100:
			return "풀 타임";
		case 101:
			return "초과 근무 후 풀타임";
		default:
			break;
	}

	if (sportId === "6046") {
		//futebol
		switch (period.value) {
			case 10:
				return "전반전";
			case 20:
				return "후반전";
			case 25:
				return "3전반전";
			case 30:
				return "연장전 전반전";
			case 35:
				return "연장전 후반전";
			case 50:
				return "페널티킥";
			case 102:
				return "페널티킥 이후 풀타임";
		}
	}
	if (sportId === "154914") {
		//baseball

		switch (true) {
			case period.value < 10:
				return `${period.value} 이닝`;
			case period.value === 40:
				return "추가의 이닝";
			case period.value === 62:
				return "Error";
		}
	}
	if (sportId === "154830") {
		//volley

		switch (true) {
			case period.value < 6:
				return `${period.value} 세트`;
			case period.value === 50:
				return "골든 세트";
			case period.value === 102:
				return "골든 세트 이후 풀타임";
		}
	}
	if (sportId === "35232") {
		//ice hockey
		switch (true) {
			case period.value < 4:
				return `${period.value} 피어리드`;
			case period.value === 40:
				return "골든 세트";
			case period.value === 50:
				return "페널티킥";
			case period.value === 102:
				return "페널티킥 이후 풀타임";
		}
		// market.name_ko.replace("", '3피어리드');
	}
	if (sportId === "48242") {
		//basquete
		switch (true) {
			case period.value < 5:
				return `${period.value}쿼터`;
			case period.value === 40:
				return "시간 외에";
		}
	}

	return "-";
};
